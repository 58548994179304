import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { kebabCase } from "lodash";
import React from "react";
import Footer from "../components/footer";
import Nav from "../components/nav";
import SEO from "../components/seo";

export default function Product({ data }: any): JSX.Element {
  const productCategories = data.ProductCategories.group;
  return (
    <>
      <SEO title="Semua Produk" />
      <Nav />
      <div
        data-aos="fade-up"
        className="px-4 mt-8 pb-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
      >
        <h1 className=" text-3xl font-bold text-gray-900">
          Semua <span className="text-primary-700">Produk</span>
        </h1>
      </div>
      {productCategories.map((el: any) => {
        const allProducts = el.edges;
        return (
          <div data-aos="fade-up" key={el.fieldValue}>
            <div className="px-4 mt-8 pb-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
              <h2 className=" text-xl font-bold text-gray-900">
                Produk <span className="text-primary-700">{el.fieldValue}</span>{" "}
                di Rupiah.trade
              </h2>
            </div>
            <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8  grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-6 mt-4">
              {allProducts.map((el: any) => {
                const usd_price = new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "USD",
                  maximumSignificantDigits: 5,
                }).format(Number(rateUSD(el.node.product_price, 14200)));
                return (
                  <Link
                    to={`/product/${kebabCase(
                      el.node.product_description
                    )}/${kebabCase(el.node.product_nominal)}`}
                    key={el.node.id}
                    role="button"
                  >
                    <div className="bg-gray-100 shadow-sm rounded-xl p-4 overflow-hidden hover:shadow-2xl hover:bg-slate-50">
                      <GatsbyImage
                        className="-m-4 mb-4"
                        image={getImage(el.node.featuredImage)}
                        alt={el.node.product_nominal}
                      />
                      <h2 className="font-semibold">
                        {el.node.product_nominal}
                      </h2>
                      <p className=" text-purple-700">{usd_price}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div className="w-full sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 mx-auto text-center py-6 border-b">
              <Link
                className="mx-auto text-lg text-primary-700 font-semibold hover:font-bold"
                to={`/product/${kebabCase(el.fieldValue)}`}
                role="button"
              >
                Lainnya
              </Link>
            </div>
          </div>
        );
      })}
<div className="h-20"></div>
      <Footer />
    </>
  );
}

const rateUSD = (total: number, rate: number) => {
  const paypalRate = rate - rate * 0.04;
  const fee = (total / paypalRate) * 0.039 + 0.3;

  let res = total / (paypalRate * 0.87) + fee;
  if (total / paypalRate > 9 && total / paypalRate < 50) {
    res = total / (paypalRate * 0.89) + fee;
  }
  if (total / paypalRate >= 50) {
    res = total / (paypalRate * 0.94) + fee;
  }
  if (!total) {
    res = 0;
  }

  return Number(res).toPrecision(2);
};

export const pageQuery = graphql`
  query ProductPageQuery {
    ProductCategories: allProduct {
      group(field: product_description, limit: 4) {
        edges {
          node {
            id
            product_nominal
            product_price
            product_description
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: WEBP
                  breakpoints: [300, 500, 600]
                )
              }
            }
          }
        }
        fieldValue
      }
    }
  }
`;
